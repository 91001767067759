<template>
  <div class="column content-box">
    <div class="row">
      <div class="inbox-conversation__date-range-wrapper">
        <h6>Select Range</h6>
        <date-range-picker @select="onDateChange" v-model="datesRange" style="min-width: 165px; text-align: center;" class="right">
          <!--    input slot (new slot syntax)-->
          <template #input="picker" style="min-width: 350px;">
            {{ picker.startDate | date }} - {{ picker.endDate | date }}
          </template>
          <!--    ranges (new slot syntax) -->
          <template #ranges="ranges">
            <div class="ranges">
              <ul>
                <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)">
                  <b>{{name}}</b> <small class="text-muted">{{range[0].toDateString()}} - {{range[1].toDateString()}}</small>
                </li>
              </ul>
            </div>
          </template>
          <!--    footer slot-->
          <div slot="footer" slot-scope="data" class="slot">
            <div>
              <b class="text-black">Selected Range</b> {{data.rangeText}}
            </div>
            <div style="margin-left: auto;">
              <a @click="data.clickApply(); fetchAllData();"  v-if="!data.in_selection" class="btn button btn-primary float-right">Select</a>
            </div>
          </div>
        </date-range-picker>
      </div>
    </div>
    <div class="row">
         <woot-report-stats-card
           v-for="(metric, index) in metrics"
           :key="metric.NAME"
           :desc="metric.DESC"
           :heading="metric.NAME"
           :index="index"
           :on-click="changeSelection"
           :point="accountSummary[metric.KEY]"
           :selected="index === currentSelection"
         />
    </div>
    <div class="report-bar">
      <woot-loading-state
        v-if="accountReport.isFetching"
        :message="$t('REPORT.LOADING_CHART')"
      />
      <div v-else class="chart-container">
        <woot-bar v-if="accountReport.data.length" :collection="collection" />
        <span v-else class="empty-state">
          {{ $t('REPORT.NO_ENOUGH_DATA') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import getUnixTime from 'date-fns/getUnixTime';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import { frontendURL } from '../../../../helper/URLHelper';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

const REPORTS_KEYS = {
  OPEN_CONVERSATION: 'open_conversations_count',
  RESOLVED_CONVERSATION: 'resolved_conversations_count',
  BOT_CONVERSATION: 'bot_conversations_count',
};
export default {
  components :{
    DateRangePicker,
  },
  data() {
    return {
      currentSelection: 0,
      currentDateRangeSelection: this.$t('REPORT.DATE_RANGE')[0],
      dateRange: this.$t('REPORT.DATE_RANGE'),
      startDate: new Date(),
      endDate: new Date(),
      datesRange: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
      True: true
    };
  },
  computed: {
    ...mapGetters({
      accountSummary: 'getAccountSummary',
      accountReport: 'getAccountReports',
      currentAccountId: 'getCurrentAccountId',
    }),
  to() {
    // return getUnixTime(startOfDay(new Date()));
    return getUnixTime(startOfDay(this.endDate));
  },
  from() {
    // const fromDate = subDays(new Date(), diff);
    // return getUnixTime(startOfDay(fromDate));
    return getUnixTime(startOfDay(this.startDate));
  },
  collection() {
    if (this.accountReport.isFetching) {
      return {};
    }
    if (!this.accountReport.data.length) return {};
    const labels = this.accountReport.data.map(element =>
      format(fromUnixTime(element.timestamp), 'dd/MMM')
    );
    const data = this.accountReport.data.map(element => element.value);
    return {
        labels,
        datasets: [
          {
            label: this.metrics[this.currentSelection].NAME,
            backgroundColor: '#1f93ff',
            data,
          },
        ],
      };
    },
    metrics() {
      const reportKeys = [
        'OPEN_CONVERSATION',
        'RESOLVED_CONVERSATION',
        'BOT_CONVERSATION',
        ];
      return reportKeys.map(key => ({
        NAME: this.$t(`REPORT.METRICS.${key}.NAME`),
        KEY: REPORTS_KEYS[key],
        DESC: this.$t(`REPORT.METRICS.${key}.DESC`),
      }));
    },
  },
mounted() {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 6);
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);

  this.startDate = startDate;
  this.endDate = new Date();
  this.datesRange = {
      startDate: startDate,
      endDate: new Date()
  }
  this.fetchAllData();
  },
  watch: {
    '$route' (to, from){
      if(this.$route.name == "account_conversations-status"){
        const startDate = new Date();
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);

        this.startDate = startDate;
        this.endDate = new Date();
        this.datesRange = {
            startDate: startDate,
            endDate: new Date()
        }
        this.fetchAllData();
      }
    }
  },
  methods: {
    fetchAllData() {
      const { from, to } = this;
      this.$store.dispatch('fetchAccountSummary', {
        from,
        to,
        agent: "",
        label: "",
        inbox: "",
      });
      this.$store.dispatch('fetchAccountReport', {
        metric: this.metrics[this.currentSelection].KEY,
        from,
        to,
        agent: "",
        label: "",
        inbox: "",
      });
    },
    changeDateSelection(selectedRange) {
      this.currentDateRangeSelection = selectedRange;
      this.fetchAllData();
    },
    changeSelection(index) {
      this.currentSelection = index;
      this.fetchChartData();
    },
    fetchChartData() {
      const { from, to } = this;
      this.$store.dispatch('fetchAccountReport', {
        metric: this.metrics[this.currentSelection].KEY,
        from,
        to,
      });
    },
    onDateChange(e){
      this.startDate = e.startDate;
      this.endDate = e.endDate;
    },
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : ''
    }
  },
};
</script>
<style scoped>
.slot {
  background-color: white;
  padding: 0.5rem;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text-black {
  color: #000;
}
.inbox-conversation__date-range-wrapper {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}
</style>
