<template>
  <div class="column content-box">
    <div class="row">
      <div class="inbox-conversation__date-range-wrapper">
        <h6>Select Range</h6>
          <date-range-picker @select="onDateChange" v-model="dateRange" style="min-width: 165px; text-align: center;" class="right">
          <!--    input slot (new slot syntax)-->
          <template #input="picker" style="min-width: 350px;">
            {{ picker.startDate | date }} - {{ picker.endDate | date }}
          </template>
          <!--    ranges (new slot syntax) -->
          <template #ranges="ranges">
            <div class="ranges">
              <ul>
                <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)">
                  <b>{{name}}</b> <small class="text-muted">{{range[0].toDateString()}} - {{range[1].toDateString()}}</small>
                </li>
              </ul>
            </div>
          </template>
          <!--    footer slot-->
          <div slot="footer" slot-scope="data" class="slot">
            <div>
              <b class="text-black">Selected Range</b> {{data.rangeText}}
            </div>
            <div style="margin-left: auto">
              <a @click="data.clickApply(); onDateSelect();"  v-if="!data.in_selection" class="btn button btn-primary">Select</a>
            </div>
          </div>
        </date-range-picker>
      </div>
    </div>


    <div class="row">
      <p v-if="!agentList.length" class="no-items-erro-message">
        {{ $t('REPORT.AGENT_STATUS.LIST.404') }}
      </p>
      <table v-if="agentList.length" class="woot-table">
        <!-- Header -->
        <thead>
          <th
            v-for="thHeader in $t('REPORT.AGENT_STATUS.LIST.TABLE_HEADER')"
            :key="thHeader"
          >
            {{ thHeader}}
          </th>
        </thead>
        <tbody v-for="(agent, index) in agentList" :key="agent.id">
          <tr>
            <td>
              <span class="agent-name">{{ agent.name }}</span>
            </td>
            <td>
              <span class="agent-availability" v-if="agent.availability_status == 'offline' || agent.availability_status == undefined">Unavailable</span>
              <span class="agent-availability" v-else>{{ agent.availability_status }}</span>
            </td>
            <td>
              <span
                class="status-time"
              >
                {{ convertTime(statusTime[index]) }}
              </span>
            </td>
          </tr>
          <tr>
            <td colspan="3" class="open-conversations">
              <conversation-report
                :conversationStatus="'open'"
                :conversationList="conversationList"
                :agentId="agent.id"
              />
            </td>
          </tr>
          <tr>
            <td colspan="3" class="resolved-conversations">
              <conversation-report
                :conversationStatus="'resolved'"
                :conversationList="conversationListResolved"
                :agentId="agent.id"
              />
            </td>
          </tr>
          <tr>
            <td colspan="3" class="bot-conversations">
              <conversation-report
                  :conversationStatus="'bot'"
                  :conversationList="conversationListBot"
                  :agentId="agent.id"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ConversationReport from './ConversationReport';
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  components: {
    DateRangePicker,
    ConversationReport,
  },
  data() {
    return {
      showHistories: false,
      agent: {},
      statusTime: [],
      conversationListResolved: [],
      conversationListBot: [],
      startDate: new Date(),
      endDate: new Date(),
      dateRange: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
      True: true
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      conversationList: 'getAllConversations',
    }),
  },
  mounted() {
    this.$store.dispatch('agents/get').then(() => {this.fetchStatusTime(this.agentList);});
    this.$store.dispatch('setChatFilter', 'open');
    this.$store.dispatch('emptyAllConversations');
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    this.startDate = startDate;
    this.endDate = new Date();
    this.dateRange = {
        startDate: startDate,
        endDate: new Date()
    }
    this.$store.dispatch('fetchAllConversations', {
      assigneeType: "all",
      status: "open",
      page: 1,
      range: [this.startDate, this.endDate]
    });
    this.$store.dispatch('inboxes/get');
    this.resetAndFetchData();
  },
  watch: {
    '$route' (to, from){
      if(this.$route.name == "agent_status_conversations"){
        const startDate = new Date();
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);

        this.startDate = startDate;
        this.endDate = new Date();
        this.datesRange = {
            startDate: startDate,
            endDate: new Date()
        }
        this.$store.dispatch('fetchAllConversations', {
          assigneeType: "all",
          status: "open",
          page: 1,
          range: [this.startDate, this.endDate]
        });
        this.$store.dispatch('inboxes/get');
        this.resetAndFetchData();
      }
    }
  },
  methods: {
    async currentStatusTime(agentId) {
      try {
        const agentHistories = await this.$store.dispatch('agents/getHistories', agentId);
        if (!agentHistories.length) {
          return '';
        } else {
          console.log(agentHistories[agentHistories.length - 1].updated_at);
          return agentHistories[agentHistories.length - 1].updated_at;
        }
      } catch (error) {
        // ignore error
      }
    },
    async fetchStatusTime(agentList) {
      for (const agent of agentList) {
        const agentHistories = await this.$store.dispatch('agents/getHistories', agent.id);
        if (agentHistories.length) {
          this.statusTime.push(agentHistories[agentHistories.length - 1].updated_at);
        } else {
          this.statusTime.push('');
        }
      }
    },
    convertTime(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD HH:mm:ss a');
    },
    resetAndFetchData() {
      this.$store.dispatch('emptyAllConversations');
      this.$store.dispatch('fetchAllConversations', {
        assigneeType: "all",
        status: "resolved",
        page: 1,
        range: [this.startDate, this.endDate]
      }).then(response => {
        const conversationListResolved = this.$store.getters['getAllConversations'];
        this.conversationListResolved = conversationListResolved.sort((a,b) => b.timestamp - a.timestamp).slice(0,5)
      });

      this.$store.dispatch('emptyAllConversations');
      this.$store.dispatch('fetchAllConversations', {
        assigneeType: "all",
        status: "bot",
        page: 1,
        range: [this.startDate, this.endDate]
      }).then(response => {
        const conversationListBot = this.$store.getters['getAllConversations'];
        this.conversationListBot = conversationListBot.sort((a, b) => b.timestamp - a.timestamp).slice(0, 5)
      });
    },
    onDateSelect() {
      this.$store.dispatch('agents/get').then(() => {
        this.fetchStatusTime(this.agentList);
      });
      this.$store.dispatch('setChatFilter', 'open');
      this.$store.dispatch('emptyAllConversations');
      this.$store.dispatch('fetchAllConversations', {
        assigneeType: "all",
        status: "open",
        page: 1,
        range: [this.startDate, this.endDate]
      });
      this.$store.dispatch('inboxes/get');
      this.resetAndFetchData();
    },
    onDateChange(e){
      this.startDate = e.startDate;
      this.endDate = e.endDate;
    }
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : ''
    }
  },
};
</script>
<style scoped>
.slot {
  background-color: white;
  padding: 0.5rem;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}

.inbox-conversation__date-range-wrapper {
    width: 100%;

    margin-bottom: 15px;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-content: center;
    align-items: center;
}
</style>
