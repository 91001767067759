<template>
  <div class="column content-box">
    <h5>
      Last Day Statistics
    </h5>
    <div class="index__buttons-wrapper">
        <div class="index__button-wrapper">
            <router-link
                    :to="`/app/accounts/${currentAccountId}/reports/conversation_status`"
            >
                <button
                        type="button"
                        class="button nice ion-shuffle"
                >
                    {{ $t('REPORT.AGENT_STATUS.DISPLAY_CONVERSATION_BUTTON_TEXT') }}
                </button>
            </router-link>
        </div>
    </div>
    <div>
      <select
        class="select-settings"
        v-model="availability"
        @change="selectAgentSettings"
        >
        <option value="all"> All Agents </option>
        <option value="active"> Active Agents </option>
        <!-- <option value="inactive"> Unavailable Agents </option> -->
      </select>
    </div>
    <div class="labels-row">
      <div class="rows">
        <div class="labels" v-for="label in overviewDaily.labels">
          <div class="label-title">
            {{label.title.replace('_', ' ')}}
          </div>
          <div class="label-body" :style="{backgroundColor: bgColorPicker(label.title)}">
            {{label.value}}
        </div>
      </div>
    </div>
  </div>
    <div class="report-bar">
      <woot-loading-state
        v-if="overviewDaily.isFetching"
        :message="$t('REPORT.LOADING_CHART')"
      />
      <div v-else class="chart-container">
        <woot-bar v-if="overviewDaily.agents.length" :collection="collection" />
        <span v-else class="empty-state">
          {{ $t('REPORT.NO_ENOUGH_DATA') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import getUnixTime from 'date-fns/getUnixTime';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import { frontendURL } from '../../../../helper/URLHelper';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  components :{
    DateRangePicker,
  },
  data() {
    return {
      availability: "all",
    };
  },
  computed: {
    ...mapGetters({
      overviewDaily: 'getOverviewDaily',
      accountSummary: 'getAccountSummary',
      currentAccountId: 'getCurrentAccountId',
      labels: 'labels/getLabels',
      agentList:'agents/getAgents'
    }),
    collection() {
      if (!this.overviewDaily.agents.length) return {};
      const labels = this.overviewDaily.agents.map(element => element.name);
      const data = this.overviewDaily.agents.map(element => element.value);
      return {
        labels,
        datasets: [
          {
            label: "Conversations",
            backgroundColor: '#1f93ff',
            data,
          },
        ],
      };
    },

  },
  mounted() {
    this.$store.dispatch('agents/get');
    this.$store.dispatch('fetchOverviewDaily', {
      availability: this.availability,
    });
  },
  watch: {
    '$route' (to, from){
      if(this.$route.name == "reports_overview"){
        this.$store.dispatch('fetchOverviewDaily', {
          availability: this.availability,
        });
      }
    }
  },
  methods: {
    selectAgentSettings(availability){
      this.$store.dispatch('fetchOverviewDaily', {
        availability: this.availability,
      });
    },
    onDateChange(e){
      this.startDate = e.startDate;
      this.endDate = e.endDate;
    },
    bgColorPicker(name){
      const color = this.labels.map(function(element){
        if(element.title == name){
          return element.color;
        }
      });
      return color;
    },
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';
.select-settings{
  width: 200px;
  position: fixed;
  right: $space-small;
  top: $space-small;
}
.index__buttons-wrapper{
  margin-top: 8px;
  margin-bottom: 4px;
}
.labels-row{
  width: 100%;
  height: 140px;
  overflow-x: scroll;
  margin-bottom: 15px;
}
.rows{
  width: max-content;
  display:flex;
  align-items: center;
  justify-content: flex-start;
}
.labels{
  overflow: hidden;

  border: solid 1px var(--b-200);
  margin: 10px 5px;
  width: 120px;
  height: 120px;
}
.label-title{
  display: flex;
  text-align: center;
  word-wrap: break-word;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: capitalize;
  align-items: center;
  justify-content: center;
  background-color: var(--b-100);
  color: var(--b-700);
  border-bottom: solid 1px var(--b-200);
  height: 40%;
  width: 100%
}
.label-body{
  display: flex;
  color: white;
  font-size: 3rem;
  font-weight: 300;
  align-items: center;
  justify-content: center;
  height:60%;
  width: 100%;
}
.slot {
  background-color: #aaa;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
