<template>
  <div class="column content-box">
    <div class="row">
      <div class="index__buttons-wrapper">
          <div class="index__button-wrapper index__button-wrapper--first">
              <router-link
                      :to="`/app/accounts/${currentAccountId}/reports/status`"
              >
                  <button
                          type="button"
                          class="button nice ion-shuffle"
                  >
                      {{ $t('REPORT.AGENT_STATUS.DISPLAY_BUTTON_TEXT') }}
                  </button>
              </router-link>
          </div>
      </div>
      <button
        class="button nice icon success button--fixed-right-top"
        @click="downloadAgentReports">
      <i class="icon ion-android-download"></i>
      {{ $t('REPORT.DOWNLOAD_AGENT_REPORTS') }}
    </button>
    </div>
    <div class="conv-header">
        <div class="multiselect-box ion-person-stalker">
          <multiselect
            v-model="agent"
            :allow-empty="true"
            :deselect-label="'Remove'"
            :options="agentList"
            :placeholder="'Agents'"
            :select-label="'Select'"
            label="name"
            track-by="id"
            @remove="removeAgent"
          >
            <span slot="noResult">{{ $t('AGENT_MGMT.SEARCH.NO_RESULTS') }}</span>
          </multiselect>
        </div>
        <div class="multiselect-box ion-pricetags">
          <multiselect
            v-model="label"
            :allow-empty="true"
            :deselect-label="'Remove'"
            :options="labelsList"
            :placeholder="'Labels'"
            :select-label="'Select'"
            label="title"
            track-by="id"
            @remove="removeLabel"
          >
            <span slot="noResult">{{ $t('AGENT_MGMT.SEARCH.NO_RESULTS') }}</span>
          </multiselect>
        </div>
        <div class="multiselect-box ion-archive">
          <multiselect
            v-model="inbox"
            :allow-empty="true"
            :deselect-label="'Remove'"
            :options="inboxList"
            :placeholder="'Inboxes'"
            :select-label="'Select'"
            label="name"
            track-by="id"
            @remove="removeInbox"
          >
            <span slot="noResult">{{ $t('AGENT_MGMT.SEARCH.NO_RESULTS') }}</span>
          </multiselect>
        </div>
        <div class="inbox-conversation__date-range-wrapper" v-if="dateRange.startDate && dateRange.endDate">
            <h6>Select Range</h6>
            <date-range-picker @select="onDateChange" v-model="dateRange" style="min-width: 165px; text-align: center;" class="right">
                <!--    input slot (new slot syntax)-->
                <template #input="picker" style="min-width: 350px;">
                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <!--    ranges (new slot syntax) -->
                <template #ranges="ranges">
                    <div class="ranges">
                        <ul>
                            <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)">
                                <b>{{name}}</b> <small class="text-muted">{{range[0].toDateString()}} - {{range[1].toDateString()}}</small>
                            </li>
                        </ul>
                    </div>
                </template>
                <!--    footer slot-->
                <div slot="footer" slot-scope="data" class="slot">
                    <div>
                        <b class="text-black">Selected Range</b> {{data.rangeText}}
                    </div>
                    <div style="margin-left: auto">
                        <a @click="data.clickApply(); onDateSelect();"  v-if="!data.in_selection" class="btn button btn-primary">Select</a>
                    </div>
                </div>
            </date-range-picker>
        </div>
        <button
          class="button nice icon success"
          @click="fetchAllData">
        Apply Options
      </button>
      <button
        class="button nice icon success"
        @click="reset">
      Reset
    </button>
  </div>
    <div class="row">
      <woot-report-stats-card
        v-for="(metric, index) in metrics"
        :key="metric.NAME"
        :desc="metric.DESC"
        :heading="metric.NAME"
        :index="index"
        :on-click="changeSelection"
        :point="accountSummary[metric.KEY]"
        :selected="index === currentSelection"
      />
    </div>
    <div class="report-bar">
      <woot-loading-state
        v-if="accountReport.isFetching"
        :message="$t('REPORT.LOADING_CHART')"
      />
      <div v-else class="chart-container">
        <woot-bar v-if="accountReport.data.length" :collection="collection" />
        <span v-else class="empty-state">
          {{ $t('REPORT.NO_ENOUGH_DATA') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import getUnixTime from 'date-fns/getUnixTime';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import { frontendURL } from '../../../../helper/URLHelper';
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

const REPORTS_KEYS = {
  CONVERSATIONS: 'conversations_count',
  INCOMING_MESSAGES: 'incoming_messages_count',
  OUTGOING_MESSAGES: 'outgoing_messages_count',
  FIRST_RESPONSE_TIME: 'avg_first_response_time',
  RESOLUTION_COUNT: 'resolutions_count',
  RESOLUTION_TIME: 'avg_resolution_time',
};

export default {
    components: {
        DateRangePicker,
    },
  data() {
    return {
      agent: "",
      label: "",
      inbox: "",
      currentSelection: 0,
        startDate: null,
        endDate: null,
        dateRange: {
            startDate: null,
            endDate: null,
        },
    };
  },
  computed: {
    ...mapGetters({
      inboxList: 'inboxes/getInboxes',
      labelsList: 'labels/getLabels',
      agentList: 'agents/getAgents',
      accountSummary: 'getAccountSummary',
      accountReport: 'getAccountReports',
      currentAccountId: 'getCurrentAccountId',
    }),
    collection() {
      if (this.accountReport.isFetching) {
        return {};
      }
      if (!this.accountReport.data.length) return {};
      const labels = this.accountReport.data.map(element =>
        format(fromUnixTime(element.timestamp), 'dd/MMM')
      );
      const data = this.accountReport.data.map(element => element.value);
      return {
        labels,
        datasets: [
          {
            label: this.metrics[this.currentSelection].NAME,
            backgroundColor: '#1f93ff',
            data,
          },
        ],
      };
    },
    to() {
      return getUnixTime(startOfDay(this.endDate));
    },
    from() {
      return getUnixTime(startOfDay(this.startDate));
    },
    metrics() {
      const reportKeys = [
        'CONVERSATIONS',
        'INCOMING_MESSAGES',
        'OUTGOING_MESSAGES',
        'FIRST_RESPONSE_TIME',
        'RESOLUTION_COUNT',
        'RESOLUTION_TIME',
      ];
      return reportKeys.map(key => ({
        NAME: this.$t(`REPORT.METRICS.${key}.NAME`),
        KEY: REPORTS_KEYS[key],
        DESC: this.$t(`REPORT.METRICS.${key}.DESC`),
      }));
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    this.startDate = startDate;
    this.endDate = new Date();
    this.dateRange = {
        startDate: startDate,
        endDate: new Date()
    }
    this.fetchAllData();
  },
  watch: {
    '$route' (to, from){
      if(this.$route.name == "settings_account_reports"){
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 6);
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);

        this.startDate = startDate;
        this.endDate = new Date();
        this.dateRange = {
            startDate: startDate,
            endDate: new Date()
        }
        this.fetchAllData();
      }
    }
  },
  methods: {
    removeAgent(){
      this.agent = "";
    },
    removeLabel(){
      this.label = "";
    },
    removeInbox(){
      this.inbox = "";
    },
    reset(){
      this.removeAgent();
      this.removeInbox();
      this.removeLabel();
      this.fetchAllData();
    },
    fetchAllData() {
      const { from, to} = this;
      this.$store.dispatch('fetchAccountSummary', {
        from,
        to,
        agent: this.agent ? this.agent.id : "",
        label: this.label ? this.label.id : "",
        inbox: this.inbox ? this.inbox.id : "",
      });
      this.$store.dispatch('fetchAccountReport', {
        metric: this.metrics[this.currentSelection].KEY,
        from,
        to,
        agent: this.agent ? this.agent.id : "",
        label: this.label ? this.label.id : "",
        inbox: this.inbox ? this.inbox.id : "",
      });
    },
    onDateSelect() {
        console.log("Selected");
    },
    changeSelection(index) {
        this.currentSelection = index;
        this.fetchChartData();
    },
    onDateChange(e){
        this.startDate = e.startDate;
        this.endDate = e.endDate;
    },
    fetchChartData() {
      const { from, to} = this;
      this.$store.dispatch('fetchAccountReport', {
        metric: this.metrics[this.currentSelection].KEY,
        from,
        to,
        agent: this.agent ? this.agent.id : "",
        label: this.label ? this.label.id : "",
        inbox: this.inbox ? this.inbox.id : "",
      });
    },
    downloadAgentReports() {
      const { from, to } = this;
      this.$store.dispatch('downloadAgentReports', {
        from,
        to,
      });
    },
  },
  filters: {
      date(val) {
          return val ? val.toLocaleString() : ''
      }
  },
};
</script>

<style scoped>
.multiselect-box{
  margin-right: 15px;
}
.conv-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button{
  margin-right: 15px;
}
.daterangepicker dropdown-menu{
  left: 100%;
}
.index__buttons-wrapper {
    width: 100%;

    display: flex;

    justify-content: flex-start;
    align-items: center;
    align-content: center;

    margin-bottom: 10px;
}

.index__button-wrapper--first {
    margin-right: 15px;
}

.inbox-conversation__date-range-wrapper {
    margin-right: 15px;
    margin-bottom: 15px;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-content: center;
    align-items: center;
}

.slot {
    background-color: white;
    padding: 0.5rem;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text-black {
    color: #000;
}
</style>
