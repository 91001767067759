import SettingsContent from '../Wrapper';
import { frontendURL } from '../../../../helper/URLHelper';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Overview from "./Overview";

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/overview'),
      component: SettingsContent,
      // props: {
      //   headerTitle: 'REPORT.HEADER',
      //   headerButtonText: 'REPORT.HEADER_BTN_TXT',
      //   icon: 'ion-arrow-graph-up-right',
      // },
      props: params => {
        return {
          headerTitle: "Overview",
          headerButtonText: 'REPORT.HEADER_BTN_TXT',
          icon: 'ion-arrow-graph-up-right',
          showBackButton: false,
          showNewButton: false,
        };
      },
      children: [
        {
          path: '',
          name: 'reports_overview',
          roles: ['administrator'],
          component: Overview,
        },
      ],
    },
  ],
};
